import tw from "twin.macro"
import dynamic from "next/dynamic"

import API from "lib/api"
import { MetaFields } from "components/Metadata"

import Zone, { query } from "components/dynamic/Zone"
import withSSRUser from "lib/withSSRUser"
import checkPageDataAuth from "lib/checkPageDataAuth"

const HeroGeneric = dynamic(import("components/Heroes/HeroGeneric"))

export default function GenericPage({ pageData }) {
  return (
    <>
      <HeroGeneric {...pageData?.Hero} />
      <Zone DynamicZone={pageData?.DynamicZone || []} />
    </>
  )
}

export const getServerSideProps = withSSRUser(async ({ params }) => {
  if (params.slug === "home") {
    return {
      redirect: {
        destination: "/",
      },
      props: {},
    }
  }
  const { HeroGenericQuery } = await import("components/Heroes/HeroGeneric")
  const { data, response } = await API.gql(
    `{
        genericPages
        (
          where: { UID: "${params.slug}" }
        ) 
        {
          UID
          ${MetaFields}
          Hero ${HeroGenericQuery.fields}
          DynamicZone ${query.query}
        }
      }`,
    query.fragments
  )

  const authRedirect = checkPageDataAuth(data, params.slug)
  if (authRedirect) {
    return authRedirect
  }

  const props: { [propName: string]: any } = {}
  try {
    const [{ data: theme }, { data: nav }] = await Promise.all([
      API.cachedGet("/theme", 60000),
      API.cachedGet("/navigation", 60000),
    ])
    props.theme = theme
    props.nav = nav
  } catch (e) {
    console.error(e)
  }

  if (!response.ok) {
    console.log(query.query, query.fragments)
  }

  props.pageData = data?.data?.genericPages?.[0] || {}
  return {
    props,
    notFound: !props.pageData?.UID,
  }
})
