/* eslint-disable @typescript-eslint/no-unused-vars */
/* 
This component renders the Strapi dynamic zone without including component run-time code on every page.
When creating a new component you will need to:
- (Optional) Export the prop interface and query from `/components/dynamic/index.ts`
- Import its prop interface and query
- Add its query to the `queryFragments` array
- Import the component using `next/dynamic`
- Add the component to the `Zone` switch statement
*/
import dynamic from "next/dynamic"
import {
  TextBlockParagraphQuery,
  TextBlockParagraphProps,
  TextBlockStoryQuery,
  TextBlockStoryProps,
  ImageTilesGenericQuery,
  ImageTilesGenericProps,
  ImageTilesLocationQuery,
  ImageTilesLocationProps,
  ImageTilesItineraryQuery,
  ImageTilesItineraryProps,
  ImageTilesExperienceQuery,
  ImageTilesExperienceProps,
  TextBlockImageStoryQuery,
  TextBlockImageStoryProps,
  TextBlockImageParagraphQuery,
  TextBlockImageParagraphProps,
  SlidesQuery,
  SlidesProps,
  BlockItineraryQuery,
  BlockItineraryProps,
  TextBlockDayQuery,
  TextBlockDayProps,
  TextBlockContactQuery,
  TextBlockContactProps,
  TitleQuery,
  TitleProps,
  StickyNavQuery,
  StickyNavProps,
  MapAQuery,
  MapAProps,
  MapBQuery,
  MapBProps,
  MapCQuery,
  MapCProps,
  BlockGuidesQuery,
  BlockGuidesProps,
  ParkPassReminderQuery,
  ParkPassReminderProps,
  TextBlockWithLogosQuery,
  TextBlockWithLogosProps,
  SeasonalCalendarProps,
  SeasonalCalendarQuery,
  WayFinderQuery,
  WayFinderProps,
  FilterableTilesProps,
  FilterableTilesQuery,
  LanguageQuoteProps,
  LanguageQuoteQuery,
  AlertProps,
  AlertQuery,
  LinkButtonQuery,
  ProductsQuery,
} from "components/dynamic"

type QueryFragment = {
  component: string
  fields: string
}

const queryFragments: QueryFragment[] = [
  TextBlockParagraphQuery,
  TextBlockStoryQuery,
  ImageTilesGenericQuery,
  ImageTilesLocationQuery,
  ImageTilesItineraryQuery,
  ImageTilesExperienceQuery,
  TextBlockImageStoryQuery,
  TextBlockImageParagraphQuery,
  SlidesQuery,
  BlockItineraryQuery,
  TextBlockDayQuery,
  TextBlockContactQuery,
  StickyNavQuery,
  BlockGuidesQuery,
  MapAQuery,
  MapBQuery,
  MapCQuery,
  ParkPassReminderQuery,
  TextBlockWithLogosQuery,
  SeasonalCalendarQuery,
  WayFinderQuery,
  FilterableTilesQuery,
  LanguageQuoteQuery,
  TitleQuery,
  ProductsQuery,
]

const locationQueryFragments: QueryFragment[] = [
  MapAQuery,
  TextBlockParagraphQuery,
  TextBlockStoryQuery,
  TextBlockDayQuery,
  TextBlockContactQuery,
  SlidesQuery,
  ImageTilesGenericQuery,
  ImageTilesLocationQuery,
  ImageTilesItineraryQuery,
  ImageTilesExperienceQuery,
  TextBlockImageStoryQuery,
  TextBlockImageParagraphQuery,
  BlockItineraryQuery,
  BlockGuidesQuery,
  WayFinderQuery,
  LanguageQuoteQuery,
  AlertQuery,
]

const itineraryQueryFragments: QueryFragment[] = [
  TextBlockParagraphQuery,
  TextBlockDayQuery,
]

const experienceQueryFragments: QueryFragment[] = [
  MapCQuery,
  TextBlockContactQuery,
  TextBlockParagraphQuery,
  BlockGuidesQuery,
  AlertQuery,
  LanguageQuoteQuery,
  TextBlockImageStoryQuery,
]

const TextBlockParagraph = dynamic(
  () => import("./text-block/TextBlockParagraph")
)
const TextBlockStory = dynamic(() => import("./text-block/TextBlockStory"))
const ImageTilesGeneric = dynamic(
  () => import("./image-tiles/ImageTilesGeneric")
)
const ImageTilesLocation = dynamic(
  () => import("./image-tiles/ImageTilesLocation")
)
const ImageTilesItinerary = dynamic(
  () => import("./image-tiles/ImageTilesItinerary")
)
const ImageTilesExperience = dynamic(() => import("./image-tiles/Experiences"))
const TextBlockImageStory = dynamic(
  () => import("./text-block-image/TextBlockImageStory")
)
const TextBlockImageParagraph = dynamic(
  () => import("./text-block-image/TextBlockImageParagraph")
)
const Slides = dynamic(() => import("./slides/Slides"))
const BlockItinerary = dynamic(() => import("./block/BlockItinerary"))
const TextBlockDay = dynamic(() => import("./text-block/TextBlockDay"))
const TextBlockContact = dynamic(() => import("./text-block/TextBlockContact"))
const StickyNav = dynamic(() => import("./sticky-nav/StickyNav"))
const MapA = dynamic(() => import("./map/MapA"))
const MapB = dynamic(() => import("./map/MapB"))
const MapC = dynamic(() => import("./map/MapC"))
const BlockGuides = dynamic(() => import("./block/BlockGuides"))
const ParkPassReminder = dynamic(
  () => import("./text-block-image/ParkPassReminder")
)
const TextBlockWithLogos = dynamic(
  () => import("./text-block-image/TextBlockWithLogos")
)
const SeasonalCalendar = dynamic(() => import("./carousel/SeasonalCalendar"))
const WayFinder = dynamic(() => import("./image-tiles/Wayfinder"))
const FilterableTiles = dynamic(() => import("./image-tiles/Filterable"))
const LanguageQuote = dynamic(() => import("./language/Quote"))
const Alert = dynamic(() => import("./alerts/Alert"))
const TripPlanner = dynamic(() => import("../TripPlanner"))
const TitleComponent = dynamic(() => import("./text-block/TextBlockTitle"))
const Products = dynamic(() => import("./image-tiles/Products"))

type Component = {
  __typename: string
  [propName: string]: any
}

export const Zone = ({ DynamicZone }: { DynamicZone: Component[] }) => (
  <main id="dynamicContent">
    {DynamicZone.map(({ __typename, id = null, ...component }, idx) => {
      const key = `idx-${idx}`
      switch (__typename) {
        case "ComponentTextBlockParagraph":
          return (
            <TextBlockParagraph
              key={key}
              {...(component as TextBlockParagraphProps)}
            />
          )
        case "ComponentTextBlockStory":
          return (
            <TextBlockStory key={key} {...(component as TextBlockStoryProps)} />
          )
        case "ComponentImageTilesGenericImagesTiles":
          return (
            <ImageTilesGeneric
              key={key}
              {...(component as ImageTilesGenericProps)}
            />
          )
        case "ComponentImageTilesLocation":
          return (
            <ImageTilesLocation
              key={key}
              {...(component as ImageTilesLocationProps)}
            />
          )
        case "ComponentImageTilesItineraryImageTiles":
          return (
            <ImageTilesItinerary
              key={key}
              {...(component as ImageTilesItineraryProps)}
            />
          )
        case "ComponentImageTilesExperienceImagesTiles":
          return (
            <ImageTilesExperience
              key={key}
              {...(component as ImageTilesExperienceProps)}
            />
          )
        case "ComponentTextBlockImageStory":
          return (
            <TextBlockImageStory
              key={key}
              {...(component as TextBlockImageStoryProps)}
            />
          )
        case "ComponentTextBlockImageParagraph":
          return (
            <TextBlockImageParagraph
              key={key}
              {...(component as TextBlockImageParagraphProps)}
            />
          )
        case "ComponentSlidesSlides":
          return <Slides key={key} {...(component as SlidesProps)} />
        case "ComponentBlockItinerary":
          return (
            <BlockItinerary key={key} {...(component as BlockItineraryProps)} />
          )
        case "ComponentTextBlockDay":
          return (
            <TextBlockDay key={key} {...(component as TextBlockDayProps)} />
          )
        case "ComponentTextBlockContact":
          return (
            <TextBlockContact
              key={key}
              {...(component as TextBlockContactProps)}
            />
          )
        case "ComponentTextBlockTitle":
          return <TitleComponent key={key} {...(component as TitleProps)} />
        case "ComponentStickyNavStickyNav":
          return <StickyNav key={key} {...(component as StickyNavProps)} />
        case "ComponentMapMapA":
          return <MapA key={key} {...(component as MapAProps)} />
        case "ComponentMapMapB":
          return <MapB key={key} {...(component as MapBProps)} />
        case "ComponentMapMapC":
          return <MapC key={key} {...(component as MapCProps)} />
        case "ComponentBlockGuides":
          return <BlockGuides key={key} {...(component as BlockGuidesProps)} />
        case "ComponentTextBlockImageParkPassReminder":
          return (
            <ParkPassReminder
              key={key}
              {...(component as ParkPassReminderProps)}
            />
          )
        case "ComponentTextBlockImageTextBlockWithLogos":
          return (
            <TextBlockWithLogos
              key={key}
              {...(component as TextBlockWithLogosProps)}
            />
          )
        case "ComponentCarouselSeasonalCalendar":
          return (
            <SeasonalCalendar
              key={key}
              {...(component as SeasonalCalendarProps)}
            />
          )
        case "ComponentImageTilesWayFinder":
          return <WayFinder key={key} {...(component as WayFinderProps)} />
        case "ComponentImageTilesFilterablePageTiles":
          return (
            <FilterableTiles
              key={key}
              {...(component as FilterableTilesProps)}
            />
          )
        case "ComponentLanguageLanguageQuote":
          return (
            <LanguageQuote key={key} {...(component as LanguageQuoteProps)} />
          )
        case "ComponentAlertAlert":
          return <Alert key={key} {...(component as AlertProps)} />
        case "ComponentInteractiveTripPlanner":
          return <TripPlanner key={key} />
        case "ComponentImageTilesProducts":
          return <Products key={key} {...(component as { Title: string })} />
        default:
          return <pre>{JSON.stringify(component, null, 2)}</pre>
      }
    })}
  </main>
)

export default Zone

export const query = {
  query: `{
    __typename
    ${queryFragments.map(({ component }) => `..._${component}Fields`)}
  }
  `,
  fragments: [...queryFragments, LinkButtonQuery]
    .map(
      ({ component, fields }) =>
        `fragment _${component}Fields on ${component} ${fields}`
    )
    .join(" "),
}

export const locationQuery = {
  query: `{
    __typename,
    ${locationQueryFragments.map(({ component }) => `..._${component}Fields`)}
  }
  `,
  fragments: locationQueryFragments
    .map(
      ({ component, fields }) =>
        `fragment _${component}Fields on ${component} ${fields}`
    )
    .join(" "),
}

export const itineraryQuery = {
  query: `{
    __typename,
    ${itineraryQueryFragments.map(({ component }) => `..._${component}Fields`)}
  }
  `,
  fragments: itineraryQueryFragments
    .map(
      ({ component, fields }) =>
        `fragment _${component}Fields on ${component} ${fields}`
    )
    .join(" "),
}

export const experienceQuery = {
  query: `{
    __typename,
    ${experienceQueryFragments.map(({ component }) => `..._${component}Fields`)}
  }
  `,
  fragments: experienceQueryFragments
    .map(
      ({ component, fields }) =>
        `fragment _${component}Fields on ${component} ${fields}`
    )
    .join(" "),
}
