import API from "lib/api"
import { query } from "components/dynamic/Zone"

import GenericPage from "./[slug]"

export default GenericPage

export const getServerSideProps = async () => {
  try {
    const { data, response } = await API.gql(
      `{
        genericPages
        (
          where: { UID: "home" }
        ) 
        {
          UID,
          Hero {
            BackgroundImage {
              url,
              alternativeText
            },
            Title,
            UpperText,
            Description
          },
          DynamicZone ${query.query}
        }
      }`,
      query.fragments
    )

    const props: { [propName: string]: any } = {}
    try {
      const [{ data: theme }, { data: nav }] = await Promise.all([
        API.cachedGet("/theme", 60000),
        API.cachedGet("/navigation", 60000),
      ])
      props.theme = theme
      props.nav = nav
    } catch (e) {
      console.error(e)
    }

    if (!response.ok) {
      console.log(query.query, query.fragments)
    }

    props.pageData = data?.data?.genericPages?.[0] || {}

    return {
      notFound: !props.pageData?.UID,
      props,
    }
  } catch (e) {
    console.warn(e)
    return {
      redirect: {
        destination: "/coming-soon",
        permanent: false,
      },
    }
  }
}
